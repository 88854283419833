let ValidatorClass = function () {

    let _private = {};

    _private.instance = this;


    this.NsInvalidTimeRange  = 'invalidTimeRange';
    this.NsIsRequired        = 'isRequired';
    this.NsInvalidEmail      = 'invalidEmail';
    this.NsInvalidRegex      = 'invalidRegex';
    this.NsInvalidJson       = 'invalidJson';
    this.NsStringTooLong     = 'stringLengthTooLong';
    this.NsStringTooShort    = 'stringLengthTooShort';
    this.NsStringLength      = 'stringLength';
    this.NsNotGreaterThan    = 'notGreaterThan';
    this.NsNotLessThan       = 'notLessThan';
    this.NsValueNotBetween   = 'valueNotBetween';
    this.NsValueNotFloat     = 'valueNotFloat';
    this.NsValueNotInt       = 'valueNotInt';
    this.NsDataType          = 'dataType';
    this.NsValueNotString    = 'valueNotString';
    this.NsInvalidTimeFormat = 'invalidTimeFormat';
    this.NsInvalidDateFormat = 'invalidDateFormat';
    this.NsNotUnique         = 'notUnique';
    this.NsNotValid          = 'notValid';

    /**
     *
     * @param {*} elements
     * @param {boolean} [removeInitial]
     * @returns {boolean}
     */
    this.validateElements = function (elements, removeInitial) {
        let isValid = true;
        $(elements).each(function (i, element) {
            if (!_private.instance.validateElement(element, removeInitial)) {
                isValid = false;
            }
        });
        return isValid;
    };

    /**
     *
     * @param {*} element
     * @param {boolean} [removeInitial]
     * @returns {boolean}
     */
    this.validateElement = function (element, removeInitial = true) {
        if (!_.isUndefined(element.target)) {
            let isUserEvent = new EventHelper().isUserEvent(element);
            element         = $(element.target);
            let empty       = element.val() === '';
            if (isUserEvent && removeInitial && !empty) {
                element.parents('div.formelement').removeClass('initial-error');
            }
        } else {
            element = $(element);
        }
        if (element.length > 1) {
            return _private.instance.validateElements(element, removeInitial);
        }
        if (element.hasClass('no-validate')) {
            element.removeClass('error');
            if (removeInitial) {
                element.removeClass('initial-error');
            }
            _private.instance.removeError(element);
            return true;
        }
        let data = _private.getValidateData(element);
        if (!data) {
            return true;
        }
        let value = element.val();
        if (!data.required && (_.isUndefined(value) || value === '')) {
            _private.instance.removeError(element);
            return true;
        }
        if (data.required && !Validator.validateIsRequired(element)) {
            return false;
        }
        if (!_private.instance.validateDataType(element, data.type)) {
            return false;
        }
        let valid = true;
        if (_private.isRangeData(data)) {
            valid = _private.instance.validateRange(element, data);
        }
        if (data.type !== 'string') {
            return valid;
        }
        if (!_.isUndefined(data.email)) {
            valid = !!(_private.instance.validateEmailAddress(element) && valid);
        }
        if (!_.isUndefined(data.time)) {
            console.log(data);
        }
        if (!_.isUndefined(data.regex)) {
            valid = !!(_private.instance.validateRegex(element, data.regex) && valid);
        }
        if (data.unique) {
            _private.instance.validateUnique(element);
        }
        return valid;

    };

    /**
     *
     * @param {Object} data
     * @returns {boolean}
     */
    _private.isRangeData = function (data) {
        let isRangeData = false;
        $.each(['gt', 'lt', 'gteq', 'lteq'], function (i, field) {
            if (!_.isUndefined(data[field])) {
                isRangeData = true;
                return false;
            }
            return true;
        });
        return isRangeData;
    };

    /**
     *
     * @param {*} element
     * @returns {Object}
     */
    _private.getValidateData = function (element) {
        let attributes = {};
        let found      = false;
        let fields     = {
            'required': 'boolean',
            'unique':   'boolean',
            'type':     'string',
            'gt':       'float',
            'gteq':     'float',
            'lt':       'float',
            'lteq':     'float',
            'email':    'string',
            'regex':    'string'
        };
        $.each(fields,
            function (field, type) {
                let attr = element.attr('validate-' + field);
                if (_.isUndefined(attr)) {
                    return true;
                }
                found             = true;
                attributes[field] = _private.parseByType(attr, type);
                return true;
            });
        if (!found) {
            return false;
        }
        if (_.isUndefined(attributes.required)) {
            attributes.required = false;
        }
        if (_.isUndefined(attributes.type)) {
            attributes.type = 'string';
        }
        return attributes;
    };

    /**
     *
     * @param {*} value
     * @param {String} type
     * @returns {*}
     */
    _private.parseByType = function (value, type) {
        switch (type) {
            case 'string':
                value = value + '';
                break;
            case 'float':
                value = parseFloat(value);
                break;
            case 'integer':
            case 'unixtime':
                value = parseInt(value);
                break;
            case 'boolean':
                value = parseInt(value) === 1;
                break;
        }
        return value;
    };

    /**
     *
     * @param {*} element
     * @param {Object} data
     * @return {boolean}
     */
    this.validateRange = function (element, data) {
        element      = $(element);
        let maxError = _private.instance.NsNotLessThan;
        let minError = _private.instance.NsNotGreaterThan;
        let value    = jQuery.trim(element.val());
        if (data.type === 'string') {
            value    = value.length;
            maxError = _private.instance.NsStringTooLong;
            minError = _private.instance.NsStringTooShort;
        } else if (data.type === 'time') {
            value = EfbHelper.timeStringToMinutes(value);
        } else {
            value = element.pVal();
        }
        if (!_.isUndefined(element.attr('data-unit-type'))) {
            _private.convertValidateData(data, element);
        }
        value = parseFloat(value);
        _private.instance.removeError(element, maxError);
        _private.instance.removeError(element, minError);
        if (!_.isUndefined(data.gt) && !(value > data.gt)) {
            _private.instance.addError(element, minError, data.gt);
            return false;
        }
        if (!_.isUndefined(data.gteq) && !(value >= data.gteq)) {
            _private.instance.addError(element, minError, data.gteq);
            return false;
        }
        if (!_.isUndefined(data.lt) && !(value < data.lt)) {
            _private.instance.addError(element, maxError, data.lt);
            return false;
        }
        if (!_.isUndefined(data.lteq) && !(value <= data.lteq)) {
            _private.instance.addError(element, maxError, data.lteq);
            return false;
        }
        return true;
    };

    /**
     * Validate for empty
     *
     * @param {*} element
     * @returns {Boolean}
     */
    this.validateIsRequired = function (element) {
        element   = $(element);
        let value = element.val();
        value     = _.isArray(value) ? value.join('') : $.trim(value);
        if (value === '') {
            _private.instance.addError(element, _private.instance.NsIsRequired);
            return false;
        }
        _private.instance.removeError(element, _private.instance.NsIsRequired);
        return true;
    };

    /**
     * Validate for email-address
     *
     * @param {*} element
     * @returns {Boolean}
     */
    this.validateEmailAddress = function (element) {
        if(typeof element === 'string'){
            element = $('<input/>',{value: element});
        }
        _private.instance.removeError(element, _private.instance.NsInvalidEmail);
        let value = jQuery.trim($(element).val());

        let re   = /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
        let test = re.test(value);

        if (!test) {
            _private.instance.addError(element, _private.instance.NsInvalidEmail);
            return false;
        }
        return true;
    };

    /**
     * Validate for regex
     *
     * @param {*} element
     * @param {string} regexString
     * @returns {Boolean}
     */
    this.validateRegex = function (element, regexString) {
        _private.instance.removeError(element, _private.instance.NsInvalidRegex);
        let value    = $(element).val().toString();
        let regParts = regexString.match(/^\/(.*?)\/([gim]*)$/);
        // we got pattern string without delimiters
        let regexp   = new RegExp(regexString);
        if (regParts) {
            // the parsed pattern had delimiters and modifiers. handle them.
            regexp = new RegExp(regParts[1], regParts[2]);
        }
        if (typeof value === 'string' && !regexp.test(value)) {
            _private.instance.addError(element, _private.instance.NsInvalidRegex);
            return false;
        }
        return true;
    };

    /**
     *
     * @param {Object} data
     * @param {*} element
     */
    _private.convertValidateData = function (data, element) {
        let baseUnit = element.attr('data-unit-base');
        let viewUnit = element.attr('data-unit-view');
        let unitType = element.attr('data-unit-type');
        $.each(['gt', 'lt', 'gteq', 'lteq'], function (i, field) {
            if (_.isUndefined(data[field])) {
                return true;
            }
            data[field] = EfbHelper.round(new MathConvert().convertValue(data[field], baseUnit, viewUnit, unitType), 2);
            return true;
        });
    };

    /**
     * Validate string-length
     *
     * @param {*} element
     * @param {Integer} min
     * @param {Integer} max
     * @returns {Boolean}
     */
    this.validateStringLength = function (element, min, max) {
        _private.instance.removeError(element, _private.instance.NsStringTooLong);
        _private.instance.removeError(element, _private.instance.NsStringTooShort);
        let value = jQuery.trim($(element).val());
        if (!$(element).parent('div.formelement').hasClass('isRequired') &&
            value === '') {
            return true;
        }
        if (value.length < min) {
            _private.instance.addError(element, _private.instance.NsStringTooShort);
            return false;
        }
        if (typeof max !== 'undefined' && max !== null && value.length > max) {
            _private.instance.addError(element, _private.instance.NsStringTooLong);
            return false;
        }
        return true;
    };

    /**
     * Validate greater than
     *
     * @param {*} element
     * @param {number} min
     * @param {Boolean} inclusive
     * @param {String} [property] name of a other input witch can overwrite the min
     * @returns {Boolean}
     */
    this.validateGreaterThan = function (element, min, inclusive, property) {
        _private.instance.removeError(element, _private.instance.NsNotGreaterThan);
        let value = jQuery.trim($(element).val());
        if (!$(element).parent('div.formelement').hasClass('isRequired') &&
            value === '') {
            return true;
        }
        let label = min + '';
        if (property) {
            let propertyElement = $(element).parents('form')
                                            .find('*[name=' + property + ']');
            label               = $(propertyElement).parents('div.formelement').find('label').text();
        }
        if (inclusive) {
            if (value < min) {
                _private.instance.addError(element, _private.instance.NsNotGreaterThan, label);
                return false;
            }
        } else {
            if (value <= min) {
                _private.instance.addError(element, _private.instance.NsNotGreaterThan, label);
                return false;
            }
        }
        return true;
    };

    /**
     * Validate value between
     *
     * @param {*} element
     * @param {number} min
     * @param {number} max
     * @param {Boolean} [inclusive]
     * @returns {Boolean}
     */
    this.validateBetween = function (element, min, max, inclusive) {
        _private.instance.removeError(element, _private.instance.NsValueNotBetween);
        let value = jQuery.trim($(element).val());
        if (!$(element).parent('div.formelement').hasClass('isRequired') &&
            value === '') {
            return true;
        }
        if (inclusive) {
            if (value < min || value > max) {
                _private.instance.addError(element, _private.instance.NsValueNotBetween);
                return false;
            }
        } else {
            if (value <= min || value >= max) {
                _private.instance.addError(element, _private.instance.NsValueNotBetween);
                return false;
            }
        }
        return true;
    };

    /**
     * Validate a element datatype
     *
     * @param {*} element
     * @param {String} dataType float|int|string
     * @returns {Boolean}
     */
    this.validateDataType = function (element, dataType) {
        element     = $(element);
        let value   = element.val();
        value       = _.isArray(value) ? value.join('') : $.trim(value);
        let isValid = false;
        if (!element.parent('div.formelement').hasClass('isRequired') && value === '') {
            isValid = true;
        }
        switch (dataType) {
            case 'float':
                if (!isValid && _private.instance.getType(value) === 'string') {
                    _private.instance.addError(element, _private.instance.NsValueNotFloat);
                    return false;
                }
                _private.instance.removeError(element, _private.instance.NsValueNotFloat);
                return true;
            case 'integer':
            case 'unixtime':
                if (!isValid && _private.instance.getType(value) !== 'integer') {
                    _private.instance.addError(element, _private.instance.NsValueNotInt);
                    return false;
                }
                _private.instance.removeError(element, _private.instance.NsValueNotInt);
                return true;
            case 'string':
                if (!isValid && $.inArray(_private.instance.getType(value), ['string', 'integer', 'float']) === -1) {
                    _private.instance.addError(element, _private.instance.NsValueNotString);
                    return false;
                }
                _private.instance.removeError(element, _private.instance.NsValueNotString);
                return true;
            case 'time':
                if (!isValid && !Validator.validateTime(element)) {
                    _private.instance.addError(element, _private.instance.NsValueNotString);
                    return false;
                }
                _private.instance.removeError(element, _private.instance.NsValueNotString);
                return true;
        }
        return false;
    };

    this.validateJson = function (element) {
        element = $(element);
        if (!_private.isJsonString(element.val())) {
            _private.instance.addError(element, _private.instance.NsInvalidJson);
            return false;
        }
        _private.instance.removeError(element, _private.instance.NsInvalidJson);
        return true;
    };

    _private.isJsonString = function (str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    /**
     * Get the datatype from string
     *
     * @param {String} input
     * @returns {string}
     */
    this.getType = function (input) {
        if ((/^(-)?[\d]+(\.[\d]+)$/).exec(input)) {
            return 'float';
        } else if ((/^(-)?[\d]+$/).exec(input)) {
            return 'integer';
        }
        return 'string';
    };

    /**
     * Validate timeformat
     *
     * @param {*} element
     * @returns {Boolean}
     */
    this.validateTime = function (element) {
        let timeString = element;
        if (typeof element !== 'string') {
            timeString = $(element).val();
        }
        if (typeof timeString !== 'string') {
            return false;
        }
        let result = false;
        if (timeString.length === 5) {
            result = /^(([0-1][0-9])|([2][0-3])):([0-5][0-9])(:([0-5][0-9]))?$/i.test(timeString);
        }
        if (typeof element !== 'string') {
            _private.instance.removeError(element, _private.instance.NsInvalidTimeFormat);
            if (!result) {
                _private.instance.addError(element, _private.instance.NsInvalidTimeFormat);
            }
        }
        return result;
    };

    this.validateUnique = function (element) {
        let params   = {filter: {}};
        let name     = element.prop('name');
        let value    = element.val();
        let objectId = element.parents('form').find('input[name=object_id]').pVal();

        params.filter[name] = {like: value};
        if (objectId) {
            params.filter.object_id = {neq: objectId};
        }
        $.ajax({
            url:      urlHelper.getSimpleUrl(undefined, 'search'),
            dataType: 'json',
            data:     params,
            success:  function (response) {
                if (response.length) {
                    return _private.instance.addError(element, _private.instance.NsNotUnique);
                }
                return _private.instance.removeError(element, _private.instance.NsNotUnique);
            }
        });
    };

    /**
     * Validate date
     *
     * @param {*} element
     * @returns {Boolean}
     */
    this.validateDate = function (element) {
        let dateString = element;
        if (typeof element !== 'string') {
            dateString = $(element).val();
        }
        let result = /^(\d{4})(-)(\d{2})(-)(\d{2})$/i.test(dateString);
        if (result) {
            let dateParts = dateString.split('-');
            let date      = new Date();
            let radix     = null;
            $.each(dateParts, function (i, part) {
                dateParts[i] = parseInt(part, radix);
                radix        = 10;
            });
            date.setMonth(dateParts[1] - 1);
            date.setYear(dateParts[0]);
            date.setDate(dateParts[2]);
            if (dateParts[0] !== date.getFullYear() || dateParts[1] - 1
                !== date.getMonth() || dateParts[2] !== date.getDate()) {
                result = false;
            }
        }
        if (typeof element === 'string') {
            return result;
        }
        _private.instance.removeError(element, _private.instance.NsInvalidDateFormat);
        if (!result) {
            _private.instance.addError(element, _private.instance.NsInvalidDateFormat);
        }
        return result;
    };

    /**
     * Validate if time of fromElement < toElement
     *
     * @param {*} fromElement
     * @param {*} toElement
     * @returns {Boolean}
     */
    this.validateTimeRange = function (fromElement, toElement) {
        //check if date is valid
        _private.instance.removeError(fromElement, _private.instance.NsInvalidTimeRange);
        _private.instance.removeError(toElement, _private.instance.NsInvalidTimeRange);

        let fromDate = EfbHelper.strToDate(
            $(fromElement).find('input[type=hidden]').val());
        let toDate   = EfbHelper.strToDate(
            $(toElement).find('input[type=hidden]').val());

        if (fromDate.toString() === 'Invalid Date' ||
            toDate.toString() === 'Invalid Date') {
            return false;
        }
        if (fromDate.getTime() >= toDate.getTime()) {
            _private.instance.addError(fromElement, _private.instance.NsInvalidTimeRange);
            _private.instance.addError(toElement, _private.instance.NsInvalidTimeRange);
            return false;
        }
        return true;
    };

    /**
     * Check if a element has a specified error
     *
     * @param {*} element
     * @param {String} [errorKey] errorKey
     * @returns {Boolean}
     */
    this.hasError = function (element, errorKey) {
        element = _private.getFormElement(element);
        if (typeof errorKey !== 'undefined') {
            return $(element).find('li.' + errorKey).length > 0;
        }
        return $(element).find('div.form-error').length > 0;
    };

    // /**
    //  * Add a field-info as warning
    //  *
    //  * @param {*} element
    //  * @param {String} warningKey
    //  * @param {String} errorSuffix
    //  * @param {Object} options
    //  */
    // this.addWarning = function (element, warningKey, errorSuffix, options) {
    //     _private.addFieldInfo('warning', element, warningKey, errorSuffix, options);
    // };
    //
    // /**
    //  * Remove a warning from element
    //  *
    //  * @param {*} element
    //  * @param {String} warningKey
    //  */
    // this.removeWarning = function (element, warningKey) {
    //     _private.instance.removeFieldInfo('warning', element, warningKey);
    // };

    /**
     * Add a field-info as warning
     *
     * @param {*} element
     * @param {String} errorKey
     * @param {String} [errorSuffix] errorSuffix
     * @param {Object} [options] options
     */
    this.addError = function (element, errorKey, errorSuffix, options) {
        //$(element).parents('div.formelement').removeClass('initial-error');
        _private.addFieldInfo('error', element, errorKey, errorSuffix, options);
        _private.getFormElement(element).trigger('ValidatorAddError');
    };


    /**
     * Remove errors from elements
     *
     * @param {*} elements
     * @param {String} [errorKey] errorKey
     */
    this.removeErrors = function (elements, errorKey) {
        elements.each(function (i, element) {
            _private.instance.removeError(element, errorKey);
        });
    };

    /**
     * Remove a error from element
     *
     * @param {*} element
     * @param {String} [errorKey] errorKey
     */
    this.removeError = function (element, errorKey) {
        if (errorKey !== Validator.NsIsRequired && !_private.instance.hasError(element, errorKey)) {
            return;
        }
        _private.instance.removeFieldInfo('error', element, _private.instance.NsNotValid);
        _private.instance.removeFieldInfo('error', element, errorKey);
        _private.getFormElement(element).trigger('ValidatorRemoveError');
    };

    /**
     * Add a field-info
     *
     * @param {String} type
     * @param {*} element
     * @param {String} key
     * @param {String} suffix
     * @param {Object} [options] options
     */
    _private.addFieldInfo = function (type, element, key, suffix, options) {
        element = _private.getFormElement(element);
        options = !_.isObject(options) ? {} : options;
        if (_.isUndefined(suffix)) {
            suffix = '';
        } else {
            suffix = ' ' + suffix;
        }
        if (type === 'error') {
            element.addClass('error');
            element.addClass('validationerror');
        } else {
            element.addClass('warning');
        }
        if (element.find('div.form-error').length === 0) {
            element.append(jQuery('<div/>', {'class': 'form-error'}).append(jQuery('<ul/>')));
        }
        element.find('div.form-error').find('ul').find('li.' + key).remove();
        let text = !_.isUndefined(options.text) ? options.text : EfbHelper._(type + '.' + key) + suffix;
        if (!_.isUndefined(options) && !_.isUndefined(options.replace)) {
            text = new StringHelper().sprintf(text, options.replace);
        }
        if (key !== Validator.NsIsRequired) {
            element.find('div.form-error').find('ul').append(jQuery('<li/>', {
                'text':  text,
                'class': key + ' ' + type
            }));
        }
        _private.instance.initError(element);
    };

    this.initError = function (element) {
        if ($(element).hasClass('formelement')) {
            $(element).find('div.form-error').hide();
        } else {
            let elements = $(element).find('div.formelement.error');
            $(elements).each(function (i, element) {
                _private.instance.initError(element);
            });
        }
    };

    /**
     *
     * @param {*} element
     * @returns {*}
     */
    _private.getFormElement = function (element) {
        if (!$(element).hasClass('formelement') && $(element).parent('div.formelement').length) {
            element = $(element).parent('div.formelement');
        }
        if (!$(element).hasClass('formelement')) {
            element = $(element).parents('div.formelement');
        }
        return element;
    };

    /**
     *
     * @param {string} type
     * @param {*} elements
     * @param {string} [errorKey]
     * @returns {boolean}
     */
    this.removeFieldInfo = function (type, elements, errorKey) {
        if (!$(elements).length) {
            return false;
        }
        $(elements).each(function (i, element) {
            element           = _private.getFormElement(element);
            let removeError   = true;
            let removeWarning = true;
            if (typeof errorKey !== 'undefined') {
                $(element).find('div.form-error').find('li.' + errorKey + '.' + type).remove();
                if ($(element).find('li.error').length > 0) {
                    removeError = false;
                }
                if ($(element).find('li.warning').length > 0) {
                    removeWarning = false;
                }
            }
            if (removeError) {
                $(element).removeClass('error validationerror');
                $(element).find('li.error').remove();
            }
            if (removeWarning) {
                $(element).removeClass('warning');
                $(element).find('li.warining').remove();
            }
            if (!$(element).find('li').length) {
                $(element).find('div.form-error').remove();
            }
        });
        return true;
    };

    this.setInitialErrors = function (form) {
        $(form).find('div.formelement.error').addClass('initial-error');
    };

    this.unsetInitialErrors = function (form) {
        $(form).find('div.formelement.initial-error').removeClass('initial-error');
    };

    _private.buildSelector = function (object) {
        let selector = [];
        if (_.isUndefined(object[0])) {
            return '';
        }
        let direct = !!_.isUndefined(object[0]['id']);
        $.each(object, function (i, item) {
            selector.push(direct ? item : item.id);
        });
        return selector.join(',');
    };

};

let Validator = new ValidatorClass();
